<template>
  <section class="member-level-expired section-block">
    <SectionTitle title="會員級別機制設定" @edit="showEditModal = true" />
    <el-form label-position="left">
      <div class="flex">
        <div>
          <el-form-item label="新會員加入時，自動成為等級階層 1 的會員" />
          <el-form-item v-if="!displayData.autoLevel" label="新會員預設等級名稱" />
        </div>
        <div>
          <div class="text-[#5E5E5E] el-form-item__content el-form-item">
            {{ displayData.autoLevel ? '開啟' : '關閉' }}
          </div>
          <div
            v-if="!displayData.autoLevel"
            class="text-[#5E5E5E] el-form-item__content el-form-item"
          >
            {{ displayData.nullLevelName || '無會員等級' }}
          </div>
        </div>
      </div>
    </el-form>

    <MemberLevelRulesSettingEditModal
      v-if="showEditModal"
      :configData="configData"
      :onSubmit="onChange"
      @close="showEditModal = false"
    />
  </section>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import MemberLevelRulesSettingEditModal from './MemberLevelRulesSettingEditModal.vue'

export default defineComponent({
  name: 'MemberLevelRulesSetting',
  components: { MemberLevelRulesSettingEditModal },
  props: {
    configData: { type: Object, default: () => ({}) },
    updateMemberConfig: { type: Function, default: () => {} },
  },
  setup (props) {
    const showEditModal = ref(false)
    const displayData = computed(() => {
      return {
        autoLevel: props.configData.autoLevel,
        nullLevelName: props.configData.nullLevelName,
      }
    })

    const onChange = async (payload) => {
      props.updateMemberConfig(payload)
    }

    return {
      props,
      showEditModal,
      displayData,
      onChange,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
